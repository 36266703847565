<template>
  <div
    id="action-list"
    class="flex justify-start md:justify-center md:cursor-none px-6 pt-6 md:pt-0 pb-20 md:pb-14"
    @mouseenter="
      setCursor($t('cant-miss-it'), cursorAction, {
        'font-size': '14px',
        width: '100px',
        height: '100px',
      })
    "
  >
    <div
      class="hidden p-8 md:grid md:grid-cols-2 md:p-10 md:px-0 justify-center max-w-7xl"
    >
      <div v-if="eventTitle" class="action-list-container md:mr-16 py-6">
        <div class="flex items-center h-full">
          <div>
            <div class="action-list-date pb-5">
              {{ date }}
              <span v-if="timeFrom">{{ timeFrom }} - {{ timeEnd }}</span>
            </div>
            <div>
              <h3 class="font-light !mb-0 pb-5">{{ eventTitle }}</h3>
            </div>
            <div class="mb-6"><p v-html="perex"></p></div>
          </div>
        </div>
      </div>
      <div class="overflow-hidden">
        <img
          class="h-full object-cover md:min-w-full mt-4 md:mt-0 md:w-full"
          :src="sourceUrl"
        />
      </div>
    </div>

    <div class="block md:hidden action-list-container py-2 md:m-6 md:mb-20">
      <div class="overflow-hidden">
        <img class="md:min-w-full mt-4 md:mt-0 md:w-full" :src="sourceUrl" />
      </div>
      <div v-if="eventTitle">
        <div class="action-list-date pt-6 pb-3">
          {{ date }} <span v-if="timeFrom">{{ timeFrom }} - {{ timeEnd }}</span>
        </div>
        <div>
          <h3 class="font-light !text-xl !mb-4">{{ eventTitle }}</h3>
        </div>
        <div class="pb-6"><p v-html="perex"></p></div>
      </div>
      <button
        class="pointer-events-auto bg-transparent border rounded-full border-black px-4 py-2 text-sm mb-8 uppercase !mb-5"
        @click="cursorAction"
      >
        {{ $t("cant-miss-it") }}
      </button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  featuredItem: Array,
  setCursor: Function,
});
const localePath = useLocalePath();
const { locale } = useI18n();
const { featuredItem } = props;

const type = computed(() => {
  return __typename === "Post" ? "article" : "akce";
});

const cursorAction = () => {
  return navigateTo(
    localePath({
      name: type.value === "article" ? "article-id" : "event-id",
      params: { id: slug },
    }),
  );
};

const { title, eventsConfig, slug, __typename } = featuredItem[0];

const datesettings = eventsConfig?.datesettings;
const perex = eventsConfig?.perex || featuredItem[0].blogConfig?.perex;
const featuredImage =
  eventsConfig?.featuredImage || featuredItem[0].featuredImage;
const eventTitle = eventsConfig?.title || title;

const formatDate = () => {
  const dateRaw = new Date(featuredItem[0].date);
  return (
    ("0" + dateRaw.getDate()).slice(-2) +
    "." +
    ("0" + (dateRaw.getMonth() + 1)).slice(-2) +
    "." +
    dateRaw.getFullYear()
  );
};

const date = datesettings?.date || formatDate(featuredItem[0].date);

const timeFrom = datesettings?.timeFrom;
const timeEnd = datesettings?.timeEnd;

const sourceUrl = featuredImage?.sourceUrl || featuredImage?.node?.sourceUrl;
// const { sourceUrl } = featuredImage;
</script>

<style scoped>
.action-list-date {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
}

.action-list-container {
  border-top: #000 1px solid;
  border-bottom: #000 1px solid;
}

h3 {
  margin-bottom: 32px;
}
</style>
